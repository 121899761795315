'use strict';

var Handlebars = require(116);
var config = require(25);
var map = require(24);

Handlebars.registerHelper('deliveryType', function(deliveryID) {
    var type = map.METHODS[deliveryID];
    return config[type].type;
});
Handlebars.registerHelper('deliveryTitle', function(deliveryID) {
    var type = map.METHODS[deliveryID];
    return config[type].title;
});

Handlebars.registerHelper('deliveryLogo', function(deliveryID) {
    var type = map.METHODS[deliveryID];
    return config[type].img;
});
