'use strict';

var Handlebars = require(116);

Handlebars.registerHelper('countryName', function(id) {
    var name;
    switch (parseInt(id)) {
        case 2397:
            name = 'Danmark';
            break;
        case 2801:
            name = 'Færøerne';
            break;
        case 2423:
            name = 'Grønland';
            break;
        case 2497:
            name = 'Norge';
            break;
        case 2540:
            name = 'Sverige';
            break;
        case 2412:
            name = 'Finland';
            break;
        case 2436:
            name = 'Island';
            break;
        case 2419:
            name = 'Tyskland';
            break;
        default:
            name = 'Danmark';
    }
    return name;
});
