'use strict';

exports.findObj = function(arr, kv_pair){
    var keys = Object.keys(kv_pair),
        foundObj = null;
    arr.forEach(function(elem) {
        keys.forEach(function(key) {
            if (elem[key] === kv_pair[key]){
                foundObj = elem;
            }
        });
    });
    return foundObj;
};
