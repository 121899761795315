// hbsfy compiled Handlebars template
var HandlebarsCompiler = require(116);
module.exports = HandlebarsCompiler.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <div class=\"row\">\n        <div class=\"col-xs-2 col-sm-2 checkout-info\"><img class=\"\" align=\"left\" src=\"\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.image : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\"></div>\n        <div class=\"col-xs-6 col-sm-6 checkout-info\"><p><a href=\""
    + escapeExpression(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"url","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "</a></p></div>\n        <div class=\"col-xs-2 col-sm-2 checkout-info\">  <div class=\"Amount\"><p>"
    + escapeExpression(((helper = (helper = helpers.quantity || (depth0 != null ? depth0.quantity : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"quantity","hash":{},"data":data}) : helper)))
    + "</p></div></div>\n        <div class=\"col-xs-2 col-sm-2 checkout-info\"> <div class=\"text-right\"><p class=\"\">"
    + escapeExpression(((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || helperMissing).call(depth0, (depth0 != null ? depth0.price : depth0), {"name":"formatCurrency","hash":{},"data":data})))
    + "</p></div></div>\n    </div>\n";
},"2":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            "
    + escapeExpression(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"image","hash":{},"data":data}) : helper)))
    + "\n";
},"4":function(depth0,helpers,partials,data) {
  return "            https://placehold.it/40\n            ";
  },"6":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.fees : depth0)) != null ? stack1.delivery : stack1)) != null ? stack1.title : stack1), depth0));
  },"8":function(depth0,helpers,partials,data) {
  return "Fragt fra";
  },"10":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return escapeExpression(((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.fees : depth0)) != null ? stack1.delivery : stack1)) != null ? stack1.price : stack1), {"name":"formatCurrency","hash":{},"data":data})));
  },"12":function(depth0,helpers,partials,data) {
  return "0,00";
  },"14":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.fees : depth0)) != null ? stack1.payment : stack1)) != null ? stack1.title : stack1), depth0));
  },"16":function(depth0,helpers,partials,data) {
  return "Betalingsgebyr fra";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"checkout-products \">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.items : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    <div class=\"row checkout-sub\">\n        <div class=\"col-xs-6 col-sm-6 checkout-info\"> <div class=\"text-left\"><p class=\"weight\">Subtotal</p></div></div>\n        <div class=\"col-xs-6 col-sm-6 checkout-info\"> <div class=\"text-right\"><p class=\"weight\">"
    + escapeExpression(((helpers.calculateSum || (depth0 && depth0.calculateSum) || helperMissing).call(depth0, (depth0 != null ? depth0.items : depth0), {"name":"calculateSum","hash":{},"data":data})))
    + "</p></div></div>\n    </div>\n</div>\n<hr>\n<div class=\"checkout-extras\">\n    <div class=\"row\">\n        <!--<div class=\"col-xs-4 col-sm-4 checkout-info\"> <div class=\"text-left\"><p class=\"weight\">Levering</p></div></div>-->\n        <div class=\"col-xs-4 col-sm-7 checkout-info\"> <div class=\"text-left\"><p id=\"typeDelievery\" class=\"weight\">";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.fees : depth0)) != null ? stack1.delivery : stack1)) != null ? stack1.name : stack1), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.program(8, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</p></div></div>\n        <div class=\"col-xs-4 col-sm-5 checkout-info\"> <div class=\"text-right\"><p id=\"priceDelievery\" class=\"\">";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.fees : depth0)) != null ? stack1.delivery : stack1)) != null ? stack1.price : stack1), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.program(12, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</p></div></div>\n    </div>\n    <div class=\"row\">\n        <!--<div class=\"col-xs-4 col-sm-4 checkout-info\"> <div class=\"text-left\"><p class=\"weight\">Betaling</p></div></div>-->\n        <div class=\"col-xs-4 col-sm-7 checkout-info\"> <div class=\"text-left\"><p id=\"typePayment\"  class=\"weight\">";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.fees : depth0)) != null ? stack1.payment : stack1)) != null ? stack1.title : stack1), {"name":"if","hash":{},"fn":this.program(14, data),"inverse":this.program(16, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</p></div></div>\n        <div class=\"col-xs-4 col-sm-5 checkout-info\"><div class=\"text-right\">\n            <p id=\"pricePayment\">"
    + escapeExpression(((helpers.formatCurrency || (depth0 && depth0.formatCurrency) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.fees : depth0)) != null ? stack1.payment : stack1)) != null ? stack1.price : stack1), {"name":"formatCurrency","hash":{},"data":data})))
    + "</p>\n        </div></div>\n    </div>\n</div>\n<hr>\n<div class=\"checkout-total\">\n    <div class=\"row checkout-total-num\">\n        <div class=\"col-xs-6 col-sm-6 checkout-info\"> <div class=\"text-left\"><p class=\"weight\">Total</p></div></div>\n        <div class=\"col-xs-6 col-sm-6 checkout-info\"> <div class=\"text-right\"><p class=\"weight\">"
    + escapeExpression(((helpers.calculateTotal || (depth0 && depth0.calculateTotal) || helperMissing).call(depth0, (depth0 != null ? depth0.items : depth0), (depth0 != null ? depth0.fees : depth0), {"name":"calculateTotal","hash":{},"data":data})))
    + "</p></div></div>\n    </div>\n</div>\n";
},"useData":true});
