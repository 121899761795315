'use strict';
// Loading Libraries
var $ = jQuery = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var Cookies = require('js-cookie');

// Load basket model
var basketModel = require('modules/models/basket');
var deliveryModel = require('modules/models/checkout/delivery-payment');

//Summary template
var summaryTemplate = require('templates/checkout/partials/summary');
var productListTemplate = require('templates/checkout/partials/products_list');

// Loading Handlebars
var Handlebars = require('hbsfy/runtime');

// Registering partials
Handlebars.registerPartial('products_list', require('templates/checkout/partials/products_list'));

require('../handlebars/helpers/format.currency.js');
require('../handlebars/helpers/calculate.sum.js');
require('../handlebars/helpers/calculate.total.js');
// require('../handlebars/helpers/calculate.fee.js');

function render() {
    _render();
    _registerEventListener();
}

function _render() {
    basketModel.getData().then(function(basket) {
        var data = {
            fees: {}
        };
        data.items = basket.items;
        var deliveryData = deliveryModel.getActiveDeliveryMethod();
        var paymentData = deliveryModel.getActivePaymentMethod();
        data.fees.delivery = (deliveryData) ? deliveryData : { title: 'Fragt fra', price: 49 };
        if (paymentData) {
            data.fees.payment = { title: paymentData.name, price: paymentData.totalFee };
        } else {
            data.fees.payment = { title: 'Betalingsgebyr fra', price: 0 };
        }
        $('.checkout-summary ').html(summaryTemplate(data));
    });
}

function _registerEventListener() {
    deliveryModel.addEventListener(_render);
}


// Exports the module
module.exports = {
    render: render
};
