'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var History = require('html5-history');
var _search = require('modules/util/search');

var API = require('../../../../configs/services').cinemagicAPI;

require('bootstrap/dist/js/bootstrap');
require('vendor/floatlabels');

require('../handlebars/helpers/select.step.js');
require('../handlebars/helpers/delivery.js');
require('../handlebars/helpers/format.currency.js');
require('../handlebars/helpers/country.name.js');

//Loading models
var basketModel = require('modules/models/basket');
var addressModel = require('modules/models/checkout/address');
var deliveryModel = require('modules/models/checkout/delivery-payment');

//Loading controller
var controller = require('modules/controllers/checkout');

// Loading templates and views
var checkoutTemplate = require('templates/checkout/checkout');
var deliveryTemplate = require('templates/checkout/delivery');
var summaryView = require('modules/views/summary');
var loaderView = require('modules/views/loader');

// Loading utils
var jsonParser = require('modules/util/jsonParser');
var Validator = require('modules/util/validator');

//Customer
var customerInfo;

var DaoCounter = 0;

function render() {
    _unbindEventListeners();

    $('.MainContainer').html(checkoutTemplate({ step: 'levering' }));
    $('body').attr('id', 'bodyCheckout');
    $('body').removeClass().addClass('webshop-orderstep2 webshop-body');
    $('.basket-value-props').appendTo($('.top-bar > .top-bar-section > .row'));

    var data = {};

    basketModel.getData().then(function(basket) {
        if (!basket.items.length) {
            controller.basketEmpty();
        }
        data.basket = basket;
        return deliveryModel.getDeliveryMethods();
    }).then(function(deliveryMethods) {
        data.delivery = deliveryMethods;
        return addressModel.getCustomer();
    }).then(function(customer) {
        data.customer = customer;
        customerInfo = customer;
        return addressModel.getAlternativeAddress();
    }).then(function(alternative) {
        data.alternative = alternative;
        data.deliveryMethod = deliveryModel.getActiveDeliveryMethod();
        data.address = data.alternative || data.customer;
        $('#checkout-content').html(deliveryTemplate(data));
        _selectActiveMethod(data.deliveryMethod);

        summaryView.render(data.basket);

        _registerEventListeners();
        _setDeliveryBoxesHeights();

        //Bootstrap popover
        $('[data-toggle="popover"]').popover();

        //Set floating labels
        $('.text-input-field-order').floatlabel({
            slideInput: false
        });

        //validators
        Validator.bindValidation($('#customerGLSNewForm'), _saveNewAddress, _getCityOnline, ['name', 'address', 'company', 'zipcode', 'city']);
        Validator.bindValidation($('.post-new-form'), _saveNewAddress, _getCityOnline, ['name', 'address', 'zipcode', 'city']);
        Validator.bindValidation($('#carrier-new-form'), _saveNewAddress, _getCityOnline, ['name', 'address', 'zipcode', 'city', 'phone']);

    });
    ga('send', {
        hitType: 'event',
        eventCategory: 'Checkout',
        eventAction: 'Render',
        eventLabel: 'Delivery',
        nonInteraction: true
    });
}

function _loadModel() {
    var data = {};
}

/*Change of delivery type*/
function _selectActiveMethod(method) {
    if (method) {
        ga('send', {
            hitType: 'event',
            eventCategory: 'Checkout',
            eventAction: 'Select',
            eventLabel: 'Delivery - ' + method.id
        });
        var el = $('#deliveryInformation .btn-delivery-menu .btn[data-shippingmethodid="' + method.id + '"]');

        el.siblings().removeClass('active');
        el.addClass('active');
        $('.delivery-method').addClass('hide-block');
        $('.delivery-method.' + method.type).removeClass('hide-block');

        if (method.type === 'gls-parcel-shop') {
            _searchParcelsMethod();
        } else if (method.type === 'dao-parcel-shop') {
            _searchDaoParcelsMethod();
        }
        $('.btn-delivery-menu').removeClass('has-error');
    }
}

function _registerEventListeners() {
    $(document).on('click', '#deliveryInformation .btn-delivery-menu .btn', function(event) {
        event.preventDefault();
        var id = $(event.currentTarget).data('shippingmethodid');
        deliveryModel.setActiveDeliveryMethodID(id);
        var deliveryMethod = deliveryModel.getActiveDeliveryMethod();
        _selectActiveMethod(deliveryMethod);
    });

    /*Starts searching on click*/
    $('[name="search-for-gls-shops"]').on('click', function(e) {
        e.preventDefault();

        ga('send', {
            hitType: 'event',
            eventCategory: 'Checkout',
            eventAction: 'Search',
            eventLabel: 'Parcel shop'
        });

        var glsAddress = $('#gls-address').val();
        var glszip = $('#gls-zipcode').val();

        var deliveryMethod = deliveryModel.getActiveDeliveryMethod();
        deliveryMethod.address = glsAddress;
        deliveryMethod.zip = glszip;
        deliveryModel.setActiveDeliveryMethod(deliveryMethod);

        _searchParcelsMethod();
    });

    $('.search-gls-input').on('keydown', function(e) {
        var code = e.which;
        if (code === 13) {
            e.preventDefault();
            var glsAddress = $('#gls-address').val();
            var glszip = $('#gls-zipcode').val();

            var deliveryMethod = deliveryModel.getActiveDeliveryMethod();
            deliveryMethod.address = glsAddress;
            deliveryMethod.zip = glszip;
            deliveryModel.setActiveDeliveryMethod(deliveryMethod);

            _searchParcelsMethod();
        }
    });

    $('[name="search-for-dao-shops"]').on('click', function(e) {
        e.preventDefault();

        var daoAddress = $('#dao-address').val();
        var daozip = $('#dao-zipcode').val();

        var deliveryMethod = deliveryModel.getActiveDeliveryMethod();
        deliveryMethod.address = daoAddress;
        deliveryMethod.zip = daozip;
        deliveryModel.setActiveDeliveryMethod(deliveryMethod);
        _searchDaoParcelsMethod();
    });

    $('.search-dao-input').on('keydown', function(e) {
        var code = e.which;
        if (code === 13) {
            e.preventDefault();
            var daoAddress = $('#dao-address').val();
            var daozip = $('#dao-zipcode').val();

            var deliveryMethod = deliveryModel.getActiveDeliveryMethod();
            deliveryMethod.address = daoAddress;
            deliveryMethod.zip = daozip;
            deliveryModel.setActiveDeliveryMethod(deliveryMethod);
            _searchDaoParcelsMethod();
        }
    });


    /*Changing address for GLS parcel delivery*/
    $('#change-gls-parcel-edit').click(function() {
        $('#change-gls-parcel #change-gls-parcel-edit').hide();
        $('#change-gls-parcel .placeholder-address').hide();
        $('#change-gls-parcel .btn-search').show();
        $('#change-gls-parcel input').removeAttr('disabled');
    });

    /*Changing address for DAO parcel delivery*/
    $('#change-dao-parcel-edit').click(function() {
        $('#change-dao-parcel #change-dao-parcel-edit').hide();
        $('#change-dao-parcel .placeholder-address').hide();
        $('#change-dao-parcel .btn-search').show();
        $('#change-dao-parcel input').removeAttr('disabled');
    });

    /*Select parcel*/
    $(document).on('click', '.parcel', function() {
        $('.parcel').removeClass('active');
        $(this).addClass('active');
        var id = $(this).data('number');
        var deliveryMethod = deliveryModel.getActiveDeliveryMethod();
        deliveryMethod.parcelShop = id;
        deliveryModel.setActiveDeliveryMethod(deliveryMethod);

        $('.gls-parcel-shop, .dao-parcel-shop').removeClass('has-error');


    });

    $(window).on('resize', _setDeliveryBoxesHeights);

    /*Change DK-post address*/
    $('button[data-action="editAlternativeAddress"]').on('click', function(event) {
        var el = $(event.currentTarget);
        var holder = el.parents('div[id$="area"]').first();
        holder.find('div[id*="current"]').fadeOut().hide();
        holder.find('div[id*="new"]').fadeIn().show();
        _editingActiveMethod();
    });
    /*Go back to dk-post address*/
    $('button[data-action="cancelAlternativeAddress"]').on('click', function(event) {
        var el = $(event.currentTarget);
        var holder = el.parents('div[id$="area"]').first();
        holder.find('div[id*="current"]').fadeIn().show();
        holder.find('div[id*="new"]').fadeOut().hide();
        _editingActiveMethodCompleted();
    });


    /*Change carrier address*/
    $('#changeCarrierAddress').click(function() {
        $('#carrier-current-address').fadeOut().hide();
        $('#carrier-new-address').fadeIn().show();
        $('#carrier-new-form').data('formValidation').resetForm();
        _editingActiveMethod();
    });
    /*Go back to carrier address*/
    $('#goBackCarrierAddress').click(function() {
        $('#carrier-new-address').fadeOut().hide();
        $('#carrier-current-address').fadeIn().show();
        _editingActiveMethodCompleted();
    });


    /*Change GLS Business address*/
    $('#changeGLSBusinessAddress').click(function() {
        $('#gls-business-current-address').fadeOut().hide();
        $('#gls-business-new-address').fadeIn().show();
        $('#customerGLSNewForm').data('formValidation').resetForm();
        _editingActiveMethod();
    });

    /*Go back to carrier address*/
    $('#goBackGLSBusinessAddress').click(function() {
        $('#gls-business-new-address').fadeOut().hide();
        $('#gls-business-current-address').fadeIn().show();
        _editingActiveMethodCompleted();
    });

    /*Change GLS Direct address*/
    $('#changeGLSHomeAddress').click(function() {
        $('#gls-home-current-address').fadeOut().hide();
        $('#gls-home-new-address').fadeIn().show();
        $('#customerGLSNewForm').data('formValidation').resetForm();
        _editingActiveMethod();
    });

    /*Go back to carrier address*/
    $('#goBackGLSHomeAddress').click(function() {
        $('#gls-home-new-address').fadeOut().hide();
        $('#gls-home-current-address').fadeIn().show();
        _editingActiveMethodCompleted();
    });


    $('#submitDeliveryInformation').on('click', function(event) {
        event.preventDefault();
        if (_validateDelivery()) {
            _submitDeliveryInformation();
        }
    });


    $('input[data-attr="zipcode"]').blur(function(event) {
        var el = $(event.currentTarget),
            fieldVal = el.val();
        _getCityOnline(el.parents('form').first());
    });


    //validators
    //DK POST
    Validator.bindValidation($('#dk-post-area .new-form'), _saveNewAddress, _getCityOnline, ['name', 'address', 'zipcode', 'city']);
    // Carrier
    Validator.bindValidation($('#carrier-area  .new-form'), _saveNewAddress, _getCityOnline, ['name', 'address', 'zipcode', 'city', 'phone']);
    // GLS
    Validator.bindValidation($('#gls-area .new-form'), _saveNewAddress, _getCityOnline, ['name', 'address', 'zipcode', 'city']);
    // GLS Business
    Validator.bindValidation($('#gls-business-area .new-form'), _saveNewAddress, _getCityOnline, ['name', 'address', 'company', 'zipcode', 'city']);
    // GLS Home
    Validator.bindValidation($('#gls-home-area .new-form'), _saveNewAddress, _getCityOnline, ['name', 'address', 'zipcode', 'city']);
}

function _editingActiveMethod() {
    var deliveryMethod = deliveryModel.getActiveDeliveryMethod();
    deliveryMethod.editing = true;
    deliveryModel.setActiveDeliveryMethod(deliveryMethod);
}

function _editingActiveMethodCompleted() {
    var deliveryMethod = deliveryModel.getActiveDeliveryMethod();
    deliveryMethod.editing = false;
    deliveryModel.setActiveDeliveryMethod(deliveryMethod);
    $('.all-delivery-area').removeClass('has-error');
}

function _unbindEventListeners() {
    $(document).off('click', '#deliveryInformation .btn-delivery-menu .btn');
    $('[name="search-for-gls-shops"]').off('click');
    $('[name="search-for-dao-shops"]').off('click');
    $('#change-gls-parcel-edit').off('click');
    $('#change-dao-parcel-edit').off('click');
    $(document).off('click', '.parcel');
    $('#changeMailingAddress').off('click');
    $('#goBackMailingAddress').off('click');
    $('#changeCarrierAddress').off('click');
    $('#goBackCarrierAddress').off('click');
    $('#changeGLSBusinessAddress').off('click');
    $('#goBackGLSBusinessAddress').off('click');
    $('#submitDeliveryInformation').off('click');
    $('input[data-attr="zipcode"]').off('blur');
    $('button[data-action="editAlternativeAddress"]').off('click');
    $('button[data-action="cancelAlternativeAddress"]').off('click');


    if (typeof $('#customerGLSNewForm').data('formValidation') !== 'undefined') {
        $('#customerGLSNewForm').data('formValidation').destroy();
    }
    if (typeof $('.post-new-form').data('formValidation') !== 'undefined') {
        $('.post-new-form').data('formValidation').destroy();
    }
    if (typeof $('#carrier-new-form').data('formValidation') !== 'undefined') {
        $('#carrier-new-form').data('formValidation').destroy();
    }

    $(window).off('resize');
}

function _setDeliveryBoxesHeights() {
    $('#deliveryInformation .btn-delivery .delivery-info').css('min-height', 0);
    var maxHeight = Math.max.apply(null, $('#deliveryInformation .btn-delivery .delivery-info').map(function() {
        return $(this).outerHeight();
    }).get());
    $('#deliveryInformation .btn-delivery .delivery-info').css('min-height', maxHeight);
}


function _searchParcelsMethod() {
    var glsAddress = $('#gls-address').val();
    var glszip = $('#gls-zipcode').val();

    var data = {
        street: glsAddress,
        zipcode: glszip,
        amount: 8
    };

    $.ajax({
        type: 'POST',
        url: API + '/get-gls-shops',
        data: data,
        contentType: 'application/x-www-form-urlencoded',
        success: function(xml) {
            $('#shops_output > div').remove();
            $(xml).find('PakkeshopData').each(function() {
                var number = $(this).find('Number').text();
                var CompanyName = $(this).find('CompanyName').text();
                var Streetname = $(this).find('Streetname').text();
                var ZipCode = $(this).find('ZipCode').text();
                var CityName = $(this).find('CityName').text();
                $('<div class=" col-sm-4 col-lg-3">' +
                    '<div class="parcel" data-number="' + number + '">' +
                    '<p class="title"><b>' + CompanyName + '</b></p>' +
                    '<p>' + Streetname + '</p>' +
                    '<p>' + ZipCode + ', ' + CityName + '</p>' +
                    '</div>' +
                    '</div>').hide().appendTo('#shops_output').show();
            });
            _selectActiveParcelShop();
        }
    });
}

function _searchDaoParcelsMethod() {
    loaderView.remove();
    loaderView.render($('#DAO-shop-area'));
    var daoAddress = $('#dao-address').val();
    var daozip = $('#dao-zipcode').val();
    var zipIsValid = (daozip && daozip.length >= 3 && daozip.length <= 4 && !isNaN(parseInt(daozip)));
    if (!zipIsValid) {
        $('#change-dao-parcel').addClass('has-error');
        return;
    }
    $('#change-dao-parcel').removeClass('has-error');
    var data = {
        zipcode: daozip,
        amount: 8
    };
    if (daoAddress.length) {
        data.addresse = encodeURI(daoAddress);
    }
    var urlParams = $.param(data);
    $.ajax({
        type: 'POST',
        url: API + '/get-dao-shops',
        data: data,
        contentType: 'application/x-www-form-urlencoded',
        timeout: 2000,
        success: function(response) {
            // remove previously fetched shops from DOM
            $('#shops_output_dao > div').remove();
            var resp = jsonParser.parse(response);
            if (resp === 0) {
                _handleDAOError();
            }
            if (resp.status && resp.status === 'OK') {
                $('#change-dao-parcel').removeClass('u-hidden');
                var parcels = resp.resultat.pakkeshops;
                if (parcels.length) {
                    $.each(parcels, function(index, value) {
                        var parcel = value;
                        var parcelObj = $('<div class=" col-sm-4 col-lg-3">' +
                            '<div class="parcel" data-number="' + parcel.shopId + '" data-type="dao">' +
                            '<p class="title"><b>' + parcel.navn + '</b></p>' +
                            '<p class="address">' + parcel.adresse + '</p>' +
                            '<p><span class="zipcode">' + parcel.postnr + '</span>, <span class="city">' + parcel.bynavn + '</span></p>' +
                            '</div>' +
                            '</div>');
                        $('#shops_output_dao').append(parcelObj);
                    });
                    _selectActiveParcelShop();
                }
            }
            loaderView.remove();
        },
        error: function(err, status) {
            console.log(err);
            if (status === 'timeout' && DaoCounter < 4) {
                DaoCounter++;
                _searchDaoParcelsMethod();
            } else {
                _handleDAOError();
                loaderView.remove();
            }
        }
    });

}

function _handleDAOError() {
    var msg = 'Når ordren er gennemført bliver du kontaktet angående udleveringssted.';
    $('#change-dao-parcel').addClass('u-hidden');
    var container = $('#shops_output_dao');
    container.html('');
    container.html('<h4>' + msg + '</h4>');
    var deliveryMethod = deliveryModel.getActiveDeliveryMethod();
    deliveryMethod.parcelShop = 9999;
    deliveryModel.setActiveDeliveryMethod(deliveryMethod);
}

function _selectActiveParcelShop() {
    var deliveryMethod = deliveryModel.getActiveDeliveryMethod();
    if (deliveryMethod) {
        var activeParcelShop = $('.parcel[data-number="' + deliveryMethod.parcelShop + '"]');
        if (activeParcelShop) {
            activeParcelShop.addClass('active');
        } else {
            deliveryMethod.parcelShop = undefined;
            deliveryModel.setActiveDeliveryMethod(deliveryMethod);
        }
    }
}

// Save new address
function _saveNewAddress(el) {
    var form = el;
    var alternativeAddress = {
        name: form.find('#name').val(),
        type: 0,
        company: '',
        address: form.find('#address').val(),
        zip: form.find('#zip').val(),
        city: form.find('#city').val(),
        country: form.find('#country :selected').data('value'),
        countryName: form.find('#country :selected').text()
    };
    if (form.find('#company').val()) {
        alternativeAddress.company = form.find('input[name="company"]').val();
        //alternativeAddress.type = 1;
    }
    if (form.find('#phone').val()) {
        alternativeAddress.phone = form.find('input[name="phone"]').val();
    }

    deliveryModel.setAlternativeAddress(alternativeAddress).then(function() {
        var newAddress = '';
        if (alternativeAddress.company) {
            newAddress += '<h4>' + alternativeAddress.company + '</h4>';
        }
        newAddress += '<h4>' + alternativeAddress.name + '</h4>';
        newAddress += '<h4>' + alternativeAddress.address + '</h4>';
        newAddress += '<h4>' + alternativeAddress.zip + ' <span>' + alternativeAddress.city + '</span></h4>';
        newAddress += '<h4>' + alternativeAddress.countryName + '</h4>';
        var holder = el.parents('div[id$="area"]').first();
        holder.find('.current-address').html(newAddress);

        holder.find('div[id*="current"]').fadeIn();
        holder.find('div[id*="new"]').fadeOut();
        _editingActiveMethodCompleted();
    });
}

//Save DAO
function _saveDAO() {
    var el = $('#shops_output_dao .parcel.active'),
        alternativeAddress = {
            name: 'Att: ' + customerInfo.name,
            type: 0,
            company: el.find('.title').text(),
            address: el.find('.address').text(),
            address2: 'Pakkeshop: #' + el.data('number'),
            zip: el.find('.zipcode').text(),
            city: el.find('.city').text(),
            country: '',
            countryName: ''
        };

    deliveryModel.setAlternativeAddress(alternativeAddress);
}


/*SENDING ALL data from delivery!*/
function _submitDeliveryInformation() {
    ga('send', {
        hitType: 'event',
        eventCategory: 'Checkout',
        eventAction: 'Continue',
        eventLabel: 'Delivery'
    });
    History.pushState({}, '', '/shop/order3.html');
}

function _getCityOnline(el) {
    $.ajax({
        type: 'GET',
        url: 'https://postnr.io/?zipcode=' + el.find('#zip').val(),
        dataType: 'json',
        async: false,
        success: function(text) {
            var response = text.result;
            if (!response.length) {
                return;
            }
            el.find('#city').val(response);
            el.formValidation('updateStatus', 'city', 'VALID')
                .formValidation('validateField', 'city');
        },
        error: function(text) {
            console.error('fejl: ', text);
        }
    });
}

function _validateDelivery() {
    var valid = true;
    var deliveryMethod = deliveryModel.getActiveDeliveryMethod();
    if (!deliveryMethod) {
        $('.btn-delivery-menu').addClass('has-error');
        ga('send', {
            hitType: 'event',
            eventCategory: 'Validataion error',
            eventAction: 'Missing delivery method'
        });
        valid = false;
    } else {
        $('.btn-delivery-menu').removeClass('has-error');
        if (deliveryMethod.type === 'gls-parcel-shop' && !deliveryMethod.parcelShop) {
            $('.gls-parcel-shop').addClass('has-error');
            ga('send', {
                hitType: 'event',
                eventCategory: 'Validataion error',
                eventAction: 'Missing parcel shop'
            });
            valid = false;
        } else {
            $('.gls-parcel-shop').removeClass('has-error');
        }
        if (deliveryMethod.type === 'dao-parcel-shop' && !deliveryMethod.parcelShop) {
            $('.dao-parcel-shop').addClass('has-error');
            valid = false;
        } else {
            $('.dao-parcel-shop').removeClass('has-error');
        }
        if (deliveryMethod.editing) {
            $('.all-delivery-area').addClass('has-error');
            ga('send', {
                hitType: 'event',
                eventCategory: 'Validataion error',
                eventAction: 'Alteranative address not saved'
            });
            valid = false;
        } else {
            $('.all-delivery-area').removeClass('has-error');
        }
    }
    return valid;
}


// Exports the module
module.exports = {
    render: render
};
