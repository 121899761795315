'use strict';

var R = require('../../helpers/_rhythm');

// Loading Libraries
var $ = jQuery = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var History = require('html5-history');
var Cookies = require('js-cookie');

//Load views
var customerView = require('modules/views/customer');
var deliveryView = require('modules/views/delivery');
var paymentView = require('modules/views/payment');

var addressModel = require('modules/models/checkout/address');
var deliveryModel = require('modules/models/checkout/delivery-payment');

var asyncCheckout = false;
var initialised = false;

var eventListeners = [];

function initialize(initialize) {
    var testingMode = Cookies.get('testingMode');
    if (initialize) {
        if (!initialised) {
            initialised = asyncCheckout = true;
            _manageStateChange();
            _changeState(window.location.pathname);
        }
    }
    _executeInitCallbacks(initialize);
}

function buildCustomer() {
    customerView.render();
}

function buildDelivery() {
    deliveryView.render();
}

function buildPayment() {
    paymentView.render();
}

function isAsyncCheckout() {
    return asyncCheckout;
}

function setTestingMode(val) {
    Cookies.set('testingMode', (val));
}

function addInitCallback(callback) {
    eventListeners.push(callback);
}

function _executeInitCallbacks(isAsync) {
    for (var i = 0; i < eventListeners.length; i++) {
        eventListeners[i](isAsync);
    }
    eventListeners = [];
}

function _changeState(viewPath) {
    switch (viewPath) {
        case '/shop/order1.html':
            History.replaceState({}, '', '/shop/order1.html');
            buildCustomer();
            break;
        case '/shop/order2.html':
            History.replaceState({}, '', '/shop/order2.html');
            buildDelivery();
            break;
        case '/shop/order3.html':
            History.replaceState({}, '', '/shop/order3.html');
            buildPayment();
            break;
        case '/shop/order4.html':
            addressModel.reset();
            deliveryModel.reset();
            break;
    }
}

function _manageStateChange() {
    History.Adapter.bind(window, 'statechange', function() {
        var State = History.getState(),
            hash = State.hash;
        switch (hash) {
            case '/shop/order1.html':
                buildCustomer();
                R.fire(R.EVENTS.LOAD);
                window.hj('vpv', '/shop/order1.html');
                break;
            case '/shop/order2.html':
                buildDelivery();
                R.fire(R.EVENTS.LOAD);
                window.hj('vpv', '/shop/order2.html');
                break;
            case '/shop/order3.html':
                buildPayment();
                R.fire(R.EVENTS.LOAD);
                window.hj('vpv', '/shop/order3.html');
                break;
            default:
                window.hj('vpv', window.location.pathname);
                window.location.href;
        }
    });
}

function _registerButtonClicks() {
    $(document).on('click', '.checkout-btn a.link', function(event) {
        event.preventDefault();
        var href = $(event.currentTarget).attr('href');
        if (href === '/shop/order1.html' || href === '/shop/order2.html' || href === '/shop/order3.html') {
            History.pushState({}, '', href);
        } else {
            window.location.href = href;
        }
    });
}

function _registerEventListeners() {
    $(document).on('click', '.StepsHeading .StepBox.done', function(event) {
        event.preventDefault();
        var trigger = $(event.currentTarget);
        var step = parseInt(trigger.find('span').eq(0).text().replace('.', ''));
        switch (step) {
            case 1:
                History.pushState({}, '', '/shop/showbasket.html');
                window.location.href = '/shop/showbasket.html';
                break;
            case 2:
                History.pushState({}, '', '/shop/order1.html');
                break;
            case 3:
                History.pushState({}, '', '/shop/order2.html');
                break;
            case 4:
                History.pushState({}, '', '/shop/order2.html');
                break;
            default:
                window.location.href = '/';
        }
    });
}

function basketEmpty() {
    window.location.href = '/shop/frontpage.html';
}


// Module public functions
var publicFunctions = {
    initialize: initialize,
    basketEmpty: basketEmpty,
    setTestingMode: setTestingMode,
    buildCustomer: buildCustomer,
    buildDelivery: buildDelivery,
    buildPayment: buildPayment,
    isAsyncCheckout: isAsyncCheckout,
    addInitCallback: addInitCallback
};

// Exports the module
module.exports = (function() {
    _registerButtonClicks();
    _registerEventListeners();
    return publicFunctions;
})();
