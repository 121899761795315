'use strict';

var Handlebars = require(116);

Handlebars.registerHelper('paymentType', function(name) {
    if (name.toLowerCase().indexOf('elektronisk') !== -1) {
        return 'electronic-bill';
    }
    return name.toLowerCase().split(' ')[0].split('/')[0];
});
Handlebars.registerHelper('paymentTitle', function(name) {
    if (name.toLowerCase().indexOf('elektronisk') !== -1) {
        return 'EAN faktuering';
    }
    if (name.toLowerCase().indexOf('americanexpress') !== -1) {
        return name.split(' ')[0];
    }
    return name;
});
