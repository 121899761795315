/* global order */
'use strict';

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var currencyParser = require('modules/util/formatters/currency');
var deliveryMap = require('configs/delivery.map');

exports.parseDeliveryMethods = function(context) {
    var $this = context.find('#ShippingMethodsContainer');
    var result = {
        shipCnt: $this.find('input[name = "shipCnt"]').val()
    };
    var resultsArr = [];
    $this.find('> table > tbody > tr').each(function() {
        var $this = $(this);
        var item = {};
        item.id = $this.find('input[name="ShippingMethodID"]').val();
        if (item.id) {
            item.name = $this.find('td > span > b').text();
            item.price = parseFloat($this.find('td:nth-child(3)').clone().children().remove().end().text().replace('(', '').replace(')', '').replace('DKK', '').replace(',', '.'));
            item.description = $this.find('td i').text();
            item.type = deliveryMap.METHODS[item.id];
            var addItem = true;
            for (var i = 0; i < resultsArr.length; i++) {
                if (resultsArr[i].name === item.name) {
                    addItem = false;
                }
            }
            if (addItem) {
                resultsArr.push(item);
            }
        }
    });
    result.items = resultsArr;
    return result;
};

exports.parsePaymentMethods = function(context) {
    var $this = context.find('#PayMethodsContainer');
    var result = {
        payCnt: $this.find('input[name = "payCnt"]').val()
    };
    var resultsArr = [];
    $this.find('> table > tbody > tr').each(function() {
        var $this = $(this);
        var item = {};
        item.id = $this.find('input[name="PaymethodID"]').val();
        item.img = $this.find('img')[0].src;
        item.name = $this.find('td > b > span').text();
        var price = $this.find('td:nth-child(3)').clone().children().remove().end().text().replace('(', '').replace(')', '');
        item.price = parseFloat(price.replace('DKK', '').replace(',', '.'));
        var fee = price.split('+')[1];
        if (typeof fee === 'undefined') {
            fee = '0,00';
        } else {
            fee = fee.split('%')[0];
        }
        item.fee = parseFloat(fee.replace(',', '.'));
        item.description = $this.find('td i').text();
        resultsArr.push(item);
    });
    result.items = resultsArr;
    return result;
};

exports.parseCheckoutIdentifier = function(context) {
    return context.find('.CheckoutIdentifier').attr('value');
};
