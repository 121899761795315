'use strict';

var deliveryData, paymentData;

var $ = jQuery = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var Cookies = require('js-cookie');
var History = require('html5-history');
var Handlebars = require('hbsfy/runtime');

require('bootstrap/dist/js/bootstrap');

require('../handlebars/helpers/select.step.js');
require('../handlebars/helpers/payment.js');
require('../handlebars/helpers/format.currency.js');
require('../handlebars/helpers/calculate.fee.js');

var _search = require('modules/util/search');
var modal = require('components/_modal');

//Loading model
var basketModel = require('modules/models/basket');
var addressModel = require('modules/models/checkout/address');
var deliveryModel = require('modules/models/checkout/delivery-payment');

//Loading controller
var controller = require('modules/controllers/checkout');

// Loading templates and views
var checkoutTemplate = require('templates/checkout/checkout');
var paymentTemplate = require('templates/checkout/payment');
var conditionsTemplate = require('templates/conditions');
var summaryView = require('modules/views/summary');
var loaderView = require('modules/views/loader');

Handlebars.registerPartial('conditions', conditionsTemplate);

// Loading utils
var jsonParser = require('modules/util/jsonParser');

function render() {
    _unbindEventListeners();

    var data = {};

    $('body').attr('id', 'bodyCheckout').find('.basket-value-props').appendTo($('.top-bar > .top-bar-section > .row'));
    $('.MainContainer').html(checkoutTemplate({ step: 'betaling' }));
    $('body').removeClass().addClass('webshop-orderstep3 webshop-body');

    basketModel.getData().then(function(basket) {
        data.basket = basket;
        if (!basket.items.length) {
            controller.basketEmpty();
        }
        return deliveryModel.getPaymentMethods();
    }).then(function(payment) {
        data.payment = payment;

        $('#checkout-content').html(paymentTemplate(data));

        data.paymentMethod = deliveryModel.getActivePaymentMethod();
        window.scrollTo(0, 0);
        _selectActiveMethod(data.paymentMethod);

        summaryView.render(data.basket);
        _registerEventListeners();
        ga('send', {
            hitType: 'event',
            eventCategory: 'Checkout',
            eventAction: 'Render',
            eventLabel: 'Payment',
            nonInteraction: true
        });

        // trigger modal script after DOM changes
        modal();

    }, function(error) {
        console.error(error);
    });
}

function _selectActiveMethod(method) {
    if (method) {
        var el = $('#paymentInformation .btn-payment-menu .btn[data-paymethodid="' + method.id + '"]');
        el.siblings().removeClass('active');
        el.addClass('active');
        $('.methods-holder').removeClass('has-error');
    }
}

function _registerEventListeners() {
    $(document).on('click', '#paymentInformation .btn-payment-menu .btn', function(event) {
        event.preventDefault();
        var id = $(event.currentTarget).data('paymethodid');
        ga('send', {
            hitType: 'event',
            eventCategory: 'Checkout',
            eventAction: 'Select',
            eventLabel: 'Payment - ' + id
        });
        deliveryModel.setActivePaymentMethodID(id);
        var paymentMethod = deliveryModel.getActivePaymentMethod();
        _selectActiveMethod(paymentMethod);
    });

    /*Making terms area better for clicking*/
    $(document).on('click', '#paymentInformation .terms h4', function() {
        var checkBoxes = $('#terms');
        checkBoxes.prop('checked', !checkBoxes.prop('checked'));
    });
    $(document).on('change', '#paymentInformation #terms', function(event) {
        var el = $(event.currentTarget);
        if (el.is(':checked')) {
            $('.terms-holder').removeClass('has-error');
        }
    });


    $('#submitPaymentInformaton').click(function(event) {
        event.preventDefault();
        var valid = _validatePayment();
        if (!valid) {
            return;
        }

        ga('send', {
            hitType: 'event',
            eventCategory: 'Checkout',
            eventAction: 'Continue',
            eventLabel: 'Payment'
        });

        loaderView.render($('#checkout-wrapper > .container'));
        deliveryModel.submit().then(function() {
            $('#checkout-content').addClass('hide');
        }, function() {
            loaderView.remove();
        });
    });
}

function _unbindEventListeners() {
    $(document).off('click', '#paymentInformation .btn-payment-menu .btn');
    $(document).off('click', '#paymentInformation .terms h4');
    $(document).off('change', '#paymentInformation #terms');
    $('#submitPaymentInformaton').off('click');
}


function _validatePayment() {
    var valid = true;
    if (!$('#terms').is(':checked')) {
        ga('send', {
            hitType: 'event',
            eventCategory: 'Validataion error',
            eventAction: 'Terms not checked'
        });
        $('.terms-holder').addClass('has-error');
        valid = false;
    }
    if (!deliveryModel.getActivePaymentMethod()) {
        $('.methods-holder').addClass('has-error');
        ga('send', {
            hitType: 'event',
            eventCategory: 'Validataion error',
            eventAction: 'Missing payment method'
        });
        valid = false;
    }
    return valid;
}


// Exports the module
module.exports = {
    render: render
};
