'use strict';

exports.METHODS = {
    60: 'pickup',
    116: 'letter',
    117: 'letter',
    118: 'letter',
    119: 'letter',
    120: 'letter',
    121: 'gls-parcel-shop',
    122: 'gls-business',
    123: 'gls-business',
    124: 'carrier',
    125: 'carrier',
    126: 'carrier',
    127: 'carrier',
    128: 'carrier',
    129: 'carrier',
    130: 'carrier',
    131: 'carrier',
    132: 'carrier',
    133: 'carrier',
    134: 'carrier',
    135: 'carrier',
    136: 'carrier',
    137: 'carrier',
    138: 'gls-business',
    139: 'carrier',
    140: 'carrier',
    141: 'carrier',
    142: 'carrier',
    143: 'carrier',
    144: 'dao-parcel-shop',
    145: 'gls-parcel-shop',
    146: 'gls-business',
    // 160401
    147: 'pickup',
    148: 'letter',
    149: 'dao-direct',
    150: 'dao-direct',
    151: 'dao-parcel-shop',
    152: 'gls-parcel-shop',
    153: 'gls-business',
    154: 'gls-business',
    155: 'dao-parcel-shop',
    156: 'gls-parcel-shop',
    157: 'gls-business',
    158: 'carrier',
    159: 'carrier',
    160: 'carrier',
    161: 'carrier',
    162: 'carrier',
    163: 'carrier',
    164: 'carrier',
    165: 'carrier',
    166: 'carrier',
    // 180323
    169: 'gls-home',
    // Test shop
    258: 'pickup',
    259: 'letter',
    260: 'dao-direct',
    261: 'dao-direct',
    262: 'dao-parcel-shop',
    263: 'gls-parcel-shop',
    264: 'gls-business',
    265: 'gls-business',
    267: 'gls-parcel-shop',
    268: 'gls-business',
    269: 'carrier',
    270: 'carrier',
    271: 'carrier',
    272: 'carrier',
    273: 'carrier',
    274: 'carrier',
    275: 'carrier',
    276: 'carrier',
    277: 'dao-parcel-shop',
    278: 'carrier',
    279: 'carrier'
};

exports.TITLES = {
    'pickup': 'Afhentning',
    'letter': 'Brevpost',
    'gls-parcel-shop': 'GLS PakkeShop',
    'gls-business': 'GLS Levering',
    'carrier': 'Fragtmand',
    'dao-parcel-shop': 'DAO PakkeShop',
    'dao-direct': 'DAO Direkte',
    'gls-home':'GLS Direkte' 
};
