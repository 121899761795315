'use strict';

var R = require('./helpers/_rhythm');

var Cookies = require('js-cookie');

var config = require('../../configs/fixtures');

// utils
var getQueryString = require('./modules/util/formatters/querystring');
var errorReporter = require('./modules/util/error');

// services
var trustpilot = require('./services/_trustpilot');

// components
var fixes = require('./components/_fixes');
var christmasReturn = require('./components/_christmas-return');
var productTile = require('./components/_product-tile');

// @TODO: disable for local dev.
global.checkout = require('modules/controllers/checkout');
require('modules/util/checkout');

R(R.EVENTS.LOAD, [R.SECTIONS.BASKET, R.SECTIONS.DETAILS, R.SECTIONS.SHIPMENT_PAYMENT, R.SECTIONS.APPROVE, R.SECTIONS.CONFIRMATION], function() {
    christmasReturn.checkoutBanner();
    fixes.checkoutFixes();
});

// update related products tiles on showbasket page
R(R.EVENTS.LOAD, [R.SECTIONS.BASKET], function() {
    productTile.update();
    R.fire(R.EVENTS.POST_LOAD);
});

// for product details and checkout views, dont activat anything during page-load
R(R.EVENTS.LOAD, [R.SECTIONS.DETAILS, R.SECTIONS.SHIPMENT_PAYMENT, R.SECTIONS.APPROVE], function() {
    R.fire(R.EVENTS.POST_LOAD);

});

// send error reports to cinemagic if DAO API fails to load
R(R.EVENTS.LOAD, [R.SECTIONS.CONFIRMATION], function() {
    var orderID = getQueryString('oid');
    if (Cookies.get('daoshop') === 'failed') {
        Cookies.remove('daoshop');
        var DAOmsg = config.errorMessage.DAO + ' Order ID: ' + orderID;
        errorReporter.send(DAOmsg, config.mail);
    }
    R.fire(R.EVENTS.POST_LOAD);
});
