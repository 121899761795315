'use strict';

module.exports = {
    'pickup': {
        type: 'pickup',
        title: 'Afhentning',
        img: '/assets/checkout/delivery/pickup.png'
    },
    'letter' : {
        type: 'letter',
        title: 'Brevpost',
        img: '/assets/checkout/delivery/mail.png'
    },
    'dao-parcel-shop': {
        type: 'dao-parcel-shop',
        title: 'DAO PakkeShop',
        img: '/assets/checkout/delivery/dao.png'
    },
    'dao-direct': {
        type: 'dao-direct',
        title: 'DAO Direkte',
        img: '/assets/checkout/delivery/dao.png'
    },
    'gls-business' : {
        type: 'GLS-erhverv',
        title: 'GLS til Erhverv',
        img: '/assets/checkout/delivery/gls.png'
    },
    'gls-parcel-shop': {
        type: 'GLS-shop',
        title: 'GLS Pakkeshop',
        img: '/assets/checkout/delivery/GlsPakkeshop.jpg'
    },
    'gls-home': {
        type: 'GLS-home',
        title: 'GLS Direkte',
        img: '/assets/checkout/delivery/gls.png'
    },
    'carrier': {
        type: 'carrier',
        title: 'Kurer',
        img: '/assets/checkout/delivery/carrier.png'
    }
};
