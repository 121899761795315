'use strict';

/**
 * NOTE: loaders CSS is bundled in checkout.css, move it outside if needed for use on other pages
 */

var $ = jQuery = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var loader = $('<div class="loading-overlay"><div class="loading"><div class="spinner">Loading…</div></div></div>');

function render(el) {
    el.append(loader);
}

function remove() {
    $('.loading-overlay').remove();
}

// Exports the module
module.exports = {
    render: render,
    remove: remove
};
