'use strict';

var Handlebars = require(116);

Handlebars.registerHelper('selectStep', function(step, current) {
    var steps = ['kurv', 'kunde', 'levering', 'betaling'],
        stepInd = steps.indexOf(step),
        currentInd = steps.indexOf(current);

    var cls = '';
    if (stepInd < currentInd) {
        cls += 'done';
    } else if (stepInd === currentInd) {
        cls += 'selected';
        cls += (stepInd === (steps.length - 1)) ? ' selected-last' : '';
    } else {
        cls += (stepInd === (steps.length - 1)) ? 'last' : '';
    }
    return cls;
});
