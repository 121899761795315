'use strict';

// Modules
var Cookies = require(121);
var Promise = require(123);

// Backend API's
var ADDRESS_API = '/shop/order1.html';

var eventListeners = [];

var basketModel = require(50);

var errorReporter = require(63);

// Module data
var data = {
    customer: undefined,
    alternative: undefined,
    checkSum: undefined
};

var basketUpdated = false;

function _submitAddress(callback) {
    return new Promise(function(resolve, reject) {
        var parameters = _getAddressFormData();
        $.ajax({
            url: ADDRESS_API,
            type: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            data: parameters,
            async: true,
            complete: function(result) {
                var error = result.responseText.match(/ToggleAltDeliveryAdress\(\);\s*\nalert\(.*\);/);
                if (error) {
                    errorReporter.send('Error when submitting address: \n\n' + JSON.stringify(parameters, null, 4));
                    ga('send', {
                        hitType: 'event',
                        eventCategory: 'Checkout',
                        eventAction: 'Submit Error',
                        eventLabel: 'Address'
                    });
                    _alertUser(error);
                    data.customer = undefined;
                    Cookies.remove('customer');
                    reject();
                } else {
                    ga('send', {
                        hitType: 'event',
                        eventCategory: 'Checkout',
                        eventAction: 'Submit OK',
                        eventLabel: 'Address'
                    });
                    resolve();
                }
            }
        });
    });
}

function _alertUser(snippet) {
    var message = snippet[0].match(/(\w|\s)+(?=("|')\);)/g);
    if (message) {
        message = message[0];
    } else {
        message = 'Der er sket en fejl. Tjek dine oplysninger og prøv igen.';
    }
    alert(message);
}

function _getAddressFormData() {
    var customer = data.customer;
    var alternative = data.alternative || {};
    var result = {
        next: 1,
        CustTypeID: customer.type,
        name: customer.name,
        company: customer.company || '',
        address: customer.address,
        address2: '',
        zipcode: customer.zip,
        city: customer.city,
        country: customer.country,
        phone: customer.phone,
        email: customer.email,
        ean: customer.ean || '',
        rekvnr: customer.rekvnr || '',
        resType1: 3,
        res1: 'Ingen',
        res3: data.parcelShop || '',
        TotalFieldCnt: 12,
        ValidateNextStep: 1,
        Order1CheckSum: data.checkSum,
        lCustTypeID: alternative.type || customer.type || '',
        lname: alternative.name || '',
        lcompany: alternative.company || '',
        laddress: alternative.address || '',
        laddress2: alternative.address2 || '',
        lphone: alternative.phone || '',
        lzipcode: alternative.zip || '',
        lcity: alternative.city || '',
        lcountry: alternative.country || customer.country,
        TotalFieldCntl: 8
    };
    if (data.alternative) {
        result.ShowAltDelivery = 1;
    }
    return result;
}

function _initialize() {
    data.customer = Cookies.getJSON('customer');
    data.alternative = Cookies.getJSON('alternative');
    _getCheckSum();
    _registerEventListener();
}

function _registerEventListener() {
    basketModel.addEventListener(function() {
        basketUpdated = true;
    });
}


function _getCheckSum() {
    $(function() {
        data.checkSum = $('input[name="Order1CheckSum"]').val() || Cookies.getJSON('checkSum');
        Cookies.set('checkSum', data.checkSum, { expires: 0.5 });
    });
}

// Sets the customer
function setCustomer(customer) {
    if (JSON.stringify(data.customer) === JSON.stringify(customer) && !basketUpdated) {
        return _resolve();
    }
    basketUpdated = false;
    data.customer = customer;
    return _submitAddress().then(function() {
        _notifyEventListeners();
        Cookies.set('customer', customer, { expires: 0.5 });
    });
}

function setCustomerLocally(customer) {
    Cookies.set('customer', customer, { expires: 0.5 });
}

function getCustomer() {
    return data.customer;
}

function setAlternativeAddress(alternative, parcelShop) {
    if (JSON.stringify(data.alternative) === JSON.stringify(alternative) && !parcelShop) {
        return _resolve();
    }
    data.alternative = alternative;
    data.parcelShop = parcelShop;
    Cookies.set('alternative', alternative, { expires: 0.5 });
    return _submitAddress();
}

function _resolve() {
    return new Promise(function(resolve, reject) {
        resolve();
    });
}

function getAlternativeAddress() {
    return data.alternative;
}


// Notifys event listeners on model change
function _notifyEventListeners() {
    for (var i = 0; i < eventListeners.length; i++) {
        var eventListener = eventListeners[i];
        eventListener(data);
    }
}

// Adds event listener for model change
function addEventListener(callback) {
    eventListeners.push(callback);
}

function reset() {
    Cookies.remove('customer');
    Cookies.remove('alternative');
    Cookies.remove('checkSum');
}



// Module public functions
var publicFunctions = {
    setCustomer: setCustomer,
    getCustomer: getCustomer,
    setCustomerLocally: setCustomerLocally,
    setAlternativeAddress: setAlternativeAddress,
    getAlternativeAddress: getAlternativeAddress,
    addEventListener: addEventListener,
    reset: reset
};

// Exports the module
module.exports = (function() {
    _initialize();
    return publicFunctions;
})();
