'use strict';

var $ = jQuery = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
require('bootstrap/dist/js/bootstrap');
require('vendor/formValidation');
require('vendor/formValidation-address');
require('vendor/formValidation-bootstrap');
require('vendor/da_DK');

var rules = {
    name: {
        trigger: 'blur',
        verbose: 'false',
        validators: {
            stringLength: {
                min: 2,
                message: 'Angiv venligst dit fulde navn. Så undgår vi leveringsfejl.'
            },
            notEmpty: {
                message: 'Udfyld venligst dette felt'
            },
            regexp: {
                regexp: '^[A-Za-z\u00C0-\u017F \-]+$',
                message: 'Brug venligst kun bogstaver'
            }
        }
    },
    address: {
        trigger: 'blur',
        verbose: 'false',
        validators: {
            address: {
                message: 'Adressen skal indeholde både gade og nummer'
            },
            stringLength: {
                max: 50,
                message: 'Systemet understøtter ikke adresser længere end 50 tegn'
            },
            notEmpty: {
                message: 'Udfyld venligst dette felt'
            }
        }
    },
    company: {
        trigger: 'blur',
        validators: {
            notEmpty: {
                message: 'Udfyld venligst dette felt'
            }
        }
    },
    zipcode: {
        trigger: 'blur',
        verbose: 'false',
        validators: {
            notEmpty: {},
            stringLength: {
                min: 4,
                max: 4,
                message: 'Brug venligst fire tal. Eventuelt med 0 foran'
            },
            integer: {
                message: 'Brug kun tal'
            }
        }
    },
    city: {
        trigger: 'blur',
        validators: {
            notEmpty: {
                message: 'Udfyld venligst dette felt'
            },
            regexp: {
                regexp: '^[A-Za-z\u00C0-\u017F ]+$',
                message: 'Brug venligst kun bogstaver'
            }
        }
    },
    ean: {
        trigger: 'blur',
        threshold: 1,
        validators: {
            ean: {},
            notEmpty: {}
        }
    },
    phone: {
        trigger: 'blur',
        validators: {
            notEmpty: {},
            stringLength: {
                min: 8,
                max: 8,
                message: 'Telefonnummeret skal være 8 cifte uden mellemrum',
                transformer: function($field, validatorName, validator) {
                    var value = $field.val();
                    value = value.replace(/\s/g, '');
                    $field.val(value);
                    return value;
                }
            },
            digits: {
                message: 'Brug ikke bogstaver eller "+" i telefonnummeret'
            }
        }
    },
    email: {
        trigger: 'blur',
        validators: {
            emailAddress: {},
            notEmpty: {},
            regexp: {
                regexp: '^[^@\\s]+@([^@\\s]+\\.)+[^@\\s]+$'
            },
            stringLength: {
                max: 50,
                message: 'Systemet tillader desværre ikke emails længere end 50 tegn'
            }
        }
    }
};

//Bind Validation
exports.bindValidation = function(el, callback, callback2, fields) {
    var validationRules = {};
    fields.forEach(function(field) {
        if (typeof rules[field] !== 'undefined') {
            validationRules[field] = rules[field];
        } else {
            console.error('There is no such validation rule');
        }
    });
    el.formValidation({
        framework: 'bootstrap',
        live: 'enabled',
        threshold: 1,
        icon: {
            valid: 'glyphicon glyphicon-ok',
            invalid: 'glyphicon glyphicon-remove',
            validating: 'glyphicon glyphicon-refresh'
        },
        excluded: [':disabled', ':hidden', ':not(:visible)'],
        locale: 'da_DK',
        fields: validationRules
    })
        .on('change', '[name="country"]', function() {
            el.formValidation('revalidateField', 'zipcode');
            callback2(el);
        })
        .on('err.validator.fv', function(e, data) {
            if (data.field === 'email') {
                data.element
                    .data('fv.messages')
                    .find('.help-block[data-fv-for="' + data.field + '"]').hide()
                    .filter('[data-fv-validator="' + data.validator + '"]').show();
            }
            data.fv.disableSubmitButtons(false);
            ga('send', {
                hitType: 'event',
                eventCategory: 'Validataion error',
                eventAction: data.field + ' - ' + data.validator,
                eventLabel: e.target.value
            });
        })
        .on('success.field.fv', function(e, data) {
            data.fv.disableSubmitButtons(false);
        })
        .on('status.field.fv', function(e, data) {
            data.fv.disableSubmitButtons(false);
        })
        .on('success.form.fv', function(e) {
            e.preventDefault();

            ga('send', {
                hitType: 'event',
                eventCategory: 'Checkout',
                eventAction: 'Valid',
                eventLabel: 'Form Address'
            });

            var $form = $(e.target);
            callback($form);
            return true;
        });
};




//Validate Customer
// exports.validateCustomer = function(el, callback, callback2) {
//  el.formValidation({
//      framework: 'bootstrap',
//      live: 'enabled',
//      icon: {
//          valid: 'glyphicon glyphicon-ok',
//          invalid: 'glyphicon glyphicon-remove',
//          validating: 'glyphicon glyphicon-refresh'
//      },
//      excluded: [':disabled', ':hidden', ':not(:visible)'],
//      locale: 'da_DK',
//      fields: {
//          name: {
//              trigger: 'blur',
//              message: 'The username is not valid',
//              validators: {
//                  fullName: {
//                      message: 'Angiv et fuldt navn'
//                  },
//                  notEmpty: {
//                      message: 'Udfyld venligst dette felt'
//                  }
//              }
//          },
//          address: {
//              trigger: 'blur',
//              validators: {
//                  address: {
//                      message: 'Der er fejl ved din adresse'
//                  },
//                  stringLength: {
//                      min: 5
//                  },
//                  notEmpty: {
//                      message: 'Udfyld venligst dette felt'
//                  }
//              }
//          },
//          company: {
//              trigger: 'blur',
//              validators: {
//                  notEmpty: {
//                      message: 'Udfyld venligst dette felt'
//                  }
//              }
//          },
//          zipcode: {
//              trigger: 'blur',
//              validators: {
//                  zip: {
//                      country: 'country',
//                      message: 'Der er fejl ved din Post nr.'
//                  },
//                  notEmpty: {}
//              }
//          },
//          city: {
//              trigger: 'blur',
//              validators: {
//                  notEmpty: {
//                      message: 'Udfyld venligst dette felt'
//                  }
//              }
//          },
//          ean: {
//              trigger: 'blur',
//              threshold: 1,
//              validators: {
//                  ean: {},
//                  notEmpty: {}
//              }
//          },
//          phone: {
//              trigger: 'blur',
//              validators: {
//                  phone: {
//                      country: 'country'
//                  },
//                  notEmpty: {}
//              }
//          },
//          email: {
//              trigger: 'blur',
//              validators: {
//                  emailAddress: {},
//                  notEmpty: {},
//                  regexp: {
//                      regexp: '^[^@\\s]+@([^@\\s]+\\.)+[^@\\s]+$'
//                  }
//              }
//          }
//      }
//  })
//  .on('change', '[name="country"]', function() {
//      el.formValidation('revalidateField', 'zipcode');
//      callback2(el);
//  })
//  .on('err.validator.fv', function(e, data) {
//      if (data.field === 'email') {
//          data.element
//              .data('fv.messages')
//              .find('.help-block[data-fv-for="' + data.field + '"]').hide()
//              .filter('[data-fv-validator="' + data.validator + '"]').show();
//      }
//      data.fv.disableSubmitButtons(false);
//  })
//  .on('success.field.fv', function(e, data) {
//      // if (data.fv.getInvalidFields().length > 0) {
//      //  data.fv.disableSubmitButtons(true);
//      // }
//      data.fv.disableSubmitButtons(false);
//  })
//     .on('status.field.fv', function(e, data) {
//      // if (data.fv.getInvalidFields().length > 0) {
//      //  data.fv.disableSubmitButtons(true);
//      // } else {
//      //  data.fv.disableSubmitButtons(false);
//      // }
//      data.fv.disableSubmitButtons(false);
//     })
//  .on('success.form.fv', function(e) {
//      e.preventDefault();
//
//      var $form = $(e.target);
//         callback($form);
//      return true;
//  });
// };


//Validate Addresses
// exports.startGLSCompanyValidate = function (callback, callback2) {
//     $('#customerGLSNewForm')
//         .formValidation({
//             framework: 'bootstrap',
//             live: 'enabled',
//             icon: {
//                 valid: 'glyphicon glyphicon-ok',
//                 invalid: 'glyphicon glyphicon-remove',
//                 validating: 'glyphicon glyphicon-refresh'
//             },
//             locale: 'da_DK',
//             fields: {
//              name: {
//                  trigger: 'blur',
//                  message: 'The username is not valid',
//                  validators: {
//                      fullName: {
//                          message: 'Angiv et fuldt navn'
//                      },
//                      notEmpty: {
//                          message: 'Udfyld venligst dette felt'
//                      }
//                  }
//              },
//                 company: {
//                     validators: {
//                         notEmpty: {
//                             message: 'Udfyld venligst dette felt'
//                         }
//                     }
//                 },
//              address: {
//                  trigger: 'blur',
//                  validators: {
//                      address: {
//                          message: 'Der er fejl ved din adresse'
//                      },
//                      stringLength: {
//                          min: 5
//                      },
//                      notEmpty: {
//                          message: 'Udfyld venligst dette felt'
//                      }
//                  }
//              },
//              zipcode: {
//                  trigger: 'blur',
//                  validators: {
//                      zip: {
//                          country: 'country',
//                          message: 'Der er fejl ved din Post nr.'
//                      },
//                      notEmpty: {}
//                  }
//              },
//                 city: {
//                     validators: {
//                         notEmpty: {
//                             message: 'Udfyld venligst dette felt'
//                         }
//                     }
//                 }
//             }
//         })
//         // Revalidate zip code when changing the country
//         .on('change', '[name="country"]', function(e) {
//             $('#customerGLSaddress').formValidation('revalidateField', 'zipCode');
//             var zip = $('#customerGLSaddress').find('#zip');
//             callback2($('#customerGLSaddress'));
//         })
//         .on('success.field.fv', function(e, data) {
//             if (data.fv.getInvalidFields().length > 0) { // There is invalid field
//                 data.fv.disableSubmitButtons(true);
//             }
//         })
//         .on('success.form.fv', function(e) {
//             e.preventDefault();
//             callback($(e.currentTarget));
//         });
// }

// exports.startDKPostvalidate = function(callback, callback2) {
//     $('.post-new-form')
//         .formValidation({
//             framework: 'bootstrap',
//             live: 'enabled',
//             icon: {
//                 valid: 'glyphicon glyphicon-ok',
//                 invalid: 'glyphicon glyphicon-remove',
//                 validating: 'glyphicon glyphicon-refresh'
//             },
//             locale: 'da_DK',
//             fields: {
//              name: {
//                  trigger: 'blur',
//                  message: 'The username is not valid',
//                  validators: {
//                      fullName: {
//                          message: 'Angiv et fuldt navn'
//                      },
//                      notEmpty: {
//                          message: 'Udfyld venligst dette felt'
//                      }
//                  }
//              },
//              address: {
//                  trigger: 'blur',
//                  validators: {
//                      address: {
//                          message: 'Der er fejl ved din adresse'
//                      },
//                      stringLength: {
//                          min: 5
//                      },
//                      notEmpty: {
//                          message: 'Udfyld venligst dette felt'
//                      }
//                  }
//              },
//              zipcode: {
//                  trigger: 'blur',
//                  validators: {
//                      zip: {
//                          country: 'country',
//                          message: 'Der er fejl ved din Post nr.'
//                      },
//                      notEmpty: {}
//                  }
//              },
//                 city: {
//                     validators: {
//                         notEmpty: {
//                             message: 'Udfyld venligst dette felt'
//                         }
//                     }
//                 }
//             }
//         })
//         // Revalidate zip code when changing the country
//         .on('change', '[name="country"]', function(e) {
//             $('#dk-post-new-form').formValidation('revalidateField', 'zipCode');
//             var zip = $('#dk-post-new-form').find('#zip');
//             callback2($('#dk-post-new-form'));
//         })
//         .on('success.field.fv', function(e, data) {
//
//             if (data.fv.getInvalidFields().length > 0) { // There is invalid field
//                 data.fv.disableSubmitButtons(true);
//             }
//         })
//         .on('success.form.fv', function(e) {
//             e.preventDefault();
//             callback($(e.currentTarget));
//         });
// }

// exports.startCarrierValidate = function(callback, callback2) {
//     $('#carrier-new-form')
//         .formValidation({
//             framework: 'bootstrap',
//             live: 'enabled',
//             icon: {
//                 valid: 'glyphicon glyphicon-ok',
//                 invalid: 'glyphicon glyphicon-remove',
//                 validating: 'glyphicon glyphicon-refresh'
//             },
//             locale: 'da_DK',
//             fields: {
//              name: {
//                  trigger: 'blur',
//                  message: 'The username is not valid',
//                  validators: {
//                      fullName: {
//                          message: 'Angiv et fuldt navn'
//                      },
//                      notEmpty: {
//                          message: 'Udfyld venligst dette felt'
//                      }
//                  }
//              },
//              address: {
//                  trigger: 'blur',
//                  validators: {
//                      address: {
//                          message: 'Der er fejl ved din adresse'
//                      },
//                      stringLength: {
//                          min: 5
//                      },
//                      notEmpty: {
//                          message: 'Udfyld venligst dette felt'
//                      }
//                  }
//              },
//              zipcode: {
//                  trigger: 'blur',
//                  validators: {
//                      zip: {
//                          country: 'country',
//                          message: 'Der er fejl ved din Post nr.'
//                      },
//                      notEmpty: {}
//                  }
//              },
//                 city: {
//                     validators: {
//                         notEmpty: {
//                             message: 'Udfyld venligst dette felt'
//                         }
//                     }
//                 },
//                 phone: {
//                     validators: {
//                         phone: {
//                             country: 'country'
//                         },
//                         notEmpty: {}
//                     }
//                 }
//             }
//         })
//         // Revalidate zip code when changing the country
//         .on('change', '[name="country"]', function(e) {
//             $('#carrier-new-form').formValidation('revalidateField', 'zipCode');
//             var zip = $('#carrier-new-form').find('#zip');
//             callback2($('#carrier-new-form'));
//         })
//         .on('success.field.fv', function(e, data) {
//             if (data.fv.getInvalidFields().length > 0) { // There is invalid field
//                 data.fv.disableSubmitButtons(true);
//             }
//         })
//         .on('success.form.fv', function(e) {
//             e.preventDefault();
//             callback($(e.currentTarget));
//         });
//
// }
