'use strict';

var Handlebars = require(116);
var currencyParser = require(64);

Handlebars.registerHelper('calculateTotal', function(products, fees) {
    var totalPrice = 0;
    if (products) {
        for (var i = products.length - 1; i >= 0; i--) {
            totalPrice = totalPrice + (products[i].quantity * products[i].price);
        }
        if (typeof fees.delivery.price !== 'undefined' && fees.delivery.price) {
            totalPrice = totalPrice + fees.delivery.price;
        }
        if (typeof fees.payment.price !== 'undefined' && fees.payment.price) {
            totalPrice = totalPrice + fees.payment.price;
        }
        // if(typeof fees.payment.fee !== 'undefined' && fees.payment.fee){
        //  totalPrice = totalPrice + (( fees.payment.fee / 100 ) * totalPrice);
        // }
        return currencyParser(totalPrice);
    }
    return currencyParser(0);
});
