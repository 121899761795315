// hbsfy compiled Handlebars template
var HandlebarsCompiler = require(116);
module.exports = HandlebarsCompiler.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div id=\"checkout-wrapper\">\n    <div class=\"breadcrumbs\">\n        <div class=\"container\">\n            <div class=\"row\">\n                <div class=\"col-sm-12 sh-container\">\n                    <div class=\"StepsHeading\">\n                        <div class=\"StepBox "
    + escapeExpression(((helpers.selectStep || (depth0 && depth0.selectStep) || helperMissing).call(depth0, "kurv", (depth0 != null ? depth0.step : depth0), {"name":"selectStep","hash":{},"data":data})))
    + " Arrow\">\n                            <div>\n                                <span class=\"visible-xs\">1.</span><span class=\"hidden-xs hidden-lg\">1. Kurv</span><span class=\"visible-lg\">1. Indkøbskurv</span>\n                            </div>\n                        </div>\n                        <div class=\"StepBox "
    + escapeExpression(((helpers.selectStep || (depth0 && depth0.selectStep) || helperMissing).call(depth0, "kunde", (depth0 != null ? depth0.step : depth0), {"name":"selectStep","hash":{},"data":data})))
    + " Arrow\">\n                            <div>\n                                <span class=\"visible-xs\">2.</span><span class=\"hidden-xs hidden-lg\">2. Kunde</span><span class=\"visible-lg\">2. Kundeoplysninger</span>\n                            </div>\n                        </div>\n                        <div class=\"StepBox "
    + escapeExpression(((helpers.selectStep || (depth0 && depth0.selectStep) || helperMissing).call(depth0, "levering", (depth0 != null ? depth0.step : depth0), {"name":"selectStep","hash":{},"data":data})))
    + " Arrow \">\n                            <div>\n                                <span class=\"visible-xs\">3.</span><span class=\"hidden-xs hidden-lg\">3. Levering</span><span class=\"visible-lg\">3. Vælg levering</span>\n                            </div>\n                        </div>\n                        <div class=\"StepBox "
    + escapeExpression(((helpers.selectStep || (depth0 && depth0.selectStep) || helperMissing).call(depth0, "betaling", (depth0 != null ? depth0.step : depth0), {"name":"selectStep","hash":{},"data":data})))
    + " Arrow\">\n                            <div>\n                                <span class=\"visible-xs\">4.</span><span class=\"hidden-xs hidden-lg\">4. Betaling</span><span class=\"visible-lg\">4. Vælg betalingsform</span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class=\"container \">\n        <div class=\"row\" id=\"checkout-content\">\n            <div class=\"loading\">\n                <div class=\"spinner\">Loading…</div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});
