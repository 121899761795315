;(function($) {
    FormValidation.I18n = $.extend(true, FormValidation.I18n || {}, {
        'en_US': {
            address: {
                'default': 'Please enter a valid address'
            }
        }
    });

    FormValidation.Validator.address = {
        html5Attributes: {
            message: 'message'
        },

        validate: function(validator, $field, options) {
            var value = validator.getFieldValue($field, 'address');
            if (value === '') {
                return true;
            }
            return (/^(?=.*[a-zA-Z])(?=.*[0-9])/.test(value));
        }
    };
}(jQuery));
