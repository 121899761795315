'use strict';

// Modules
var Cookies = require(121);
var Promise = require(123);
var search = require(69);
var errorReporter = require(63);

var deliveryMap = require(24);

var DELIVERY_PAYMENT_URL = '/shop/order2.html';

// Loads checkout parsers
var parser = require(57);

var basket = require(50);
var addressModel = require(51);

var eventListeners = [];

// Module data
var data = {
    delivery: undefined,
    payment: undefined,
    //identifier: undefined,
    activeDelivery: undefined,
    activePayment: undefined
};

function _sort() {
    data.delivery.items = data.delivery.items.sort(function(a, b) {
        return a.price - b.price;
    });
    data.payment.items = data.payment.items.sort(function(a, b) {
        var x = a.price - b.price;
        return x === 0 ? a.fee - b.fee : x;
    });
    Cookies.set('payment', data.payment, { expires: 0.5 });
    Cookies.set('delivery', data.delivery, { expires: 0.5 });
}

function _setTitles() {
    for (var i = 0; i < data.delivery.items.length; i++) {
        var item = data.delivery.items[i];
        item.title = deliveryMap.TITLES[deliveryMap.METHODS[item.id]];
    }
}

function _loadMethods(callback) {
    $.ajax({
        type: 'GET',
        url: DELIVERY_PAYMENT_URL,
        success: function(content) {
            content = $('<div>' + content + '</div>');
            var result = {};
            result.delivery = parser.parseDeliveryMethods(content);
            result.payment = parser.parsePaymentMethods(content);
            if (result.delivery.items.length) {
                data.delivery = result.delivery;
                data.payment = result.payment;
                setActiveDeliveryMethod();
                setActivePaymentMethod();
                _setTitles();
                _sort();
            }
            if (callback) {
                callback();
            }
        }
    });
}


function getDeliveryMethods() {
    return new Promise(function(resolve, reject) {
        if (data.delivery) {
            resolve(data.delivery);
        } else {
            _loadMethods(function() {
                resolve(data.delivery);
            });
        }
    });
}

function getPaymentMethods() {
    return new Promise(function(resolve, reject) {
        if (data.payment) {
            resolve(data.payment);
        } else {
            _loadMethods(function() {
                resolve(data.payment);
            });
        }
    });
}

function setActiveDeliveryMethodID(id) {
    var method = undefined;
    if (id) {
        method = search.findObj(data.delivery.items, { id: id.toString() });
    }
    setActiveDeliveryMethod(method);
}

function setActiveDeliveryMethod(method) {
    if (method) {
        Cookies.set('activeDelivery', data.activeDelivery, { expires: 0.5 });
    } else {
        Cookies.remove('activeDelivery');
    }
    data.activeDelivery = method;
    _calculatePaymentFee();
    _notifyEventListeners();
}

function getActiveDeliveryMethod() {
    return data.activeDelivery;
}

function setActivePaymentMethodID(id) {
    var method;
    if (id) {
        method = search.findObj(data.payment.items, { 'id': id.toString() });
    }
    setActivePaymentMethod(method);
}

function setActivePaymentMethod(method) {
    if (method) {
        Cookies.set('activePayment', data.activePayment, { expires: 0.5 });
    } else {
        Cookies.remove('activePayment');
    }
    data.activePayment = method;
    _notifyEventListeners();
}

function getActivePaymentMethod() {
    return data.activePayment;
}

function _calculatePaymentFee() {
    basket.getData().then(function(basketData) {
        var orderTotal = basketData.basketTotal;
        if (data.activeDelivery) {
            orderTotal = orderTotal + data.activeDelivery.price;
        }
        if (data.payment) {
            data.payment.items = data.payment.items.map(function(item) {
                var fee = 0;
                if (typeof item.fee !== 'undefined' && item.fee) {
                    fee += (item.fee / 100) * orderTotal;
                }
                if (typeof item.price !== 'undefined' && item.price) {
                    fee += item.price;
                }
                item.totalFee = fee;
                return item;
            });
        }
    });
}

// Notifys event listeners on model change
function _notifyEventListeners() {
    for (var i = 0; i < eventListeners.length; i++) {
        var eventListener = eventListeners[i];
        eventListener(data);
    }
}

// Adds event listener for model change
function addEventListener(callback) {
    eventListeners.push(callback);
}



function _initialize() {
    data.activeDelivery = Cookies.getJSON('activeDelivery');
    if (data.activeDelivery === 'undefined') {
        data.activeDelivery = undefined;
        Cookies.remove('activeDelivery');
    }
    data.activePayment = Cookies.getJSON('activePayment');
    if (data.activePayment === 'undefined') {
        data.activePayment = undefined;
        Cookies.remove('activePayment');
    }
    data.delivery = Cookies.getJSON('delivery');
    data.payment = Cookies.getJSON('payment');
    //data.identifier = Cookies.getJSON('identifier');
    _calculatePaymentFee();
    _registerEventListeners();
}

function submit() {
    return _submitAPICall();
}

function _submitAPICall() {
    return new Promise(function(resolve, reject) {
        _submitAlternativeAddress().then(function() {
            var parameters = _getAPIData();
            $.ajax({
                url: '/shop/order2.html',
                type: 'POST',
                contentType: 'application/x-www-form-urlencoded',
                data: parameters,
                async: true,
                complete: function(result) {
                    var error = result.responseText.match('CurrencyReturnUrl = "/shop/order2.html"');
                    if (error) {
                        errorReporter.send('Error submitting order: \n\n' + JSON.stringify(parameters, null, 2));
                        ga('send', {
                            hitType: 'event',
                            eventCategory: 'Checkout',
                            eventAction: 'Submit Error',
                            eventLabel: 'Delivery-Payment'
                        });
                        reject();
                    } else {
                        ga('send', {
                            hitType: 'event',
                            eventCategory: 'Checkout',
                            eventAction: 'Submit OK',
                            eventLabel: 'Delivery-Payment'
                        });
                        var content = $('<div>' + result.responseText + '</div>');
                        var identifier = parser.parseCheckoutIdentifier(content);
                        //Cookies.set('identifier', identifier);
                        if (data.activeDelivery.parcelShop === 9999) {
                            Cookies.set('daoshop', 'failed', { expires: 0.5 });
                        }
                        _submitTermsAcceptedCall();
                        resolve();
                    }
                }
            });
        });
    });
}

function setAlternativeAddress(address) {
    if (address) {
        data.activeDelivery.alternative = address;
    } else {
        delete data.activeDelivery.alternative;
    }
    Cookies.set('delivery', data.delivery, { expires: 0.5 });
    return _resolve();
}



function _submitAlternativeAddress() {
    var alternativeAddress = data.activeDelivery.alternative;
    var parcelShop = data.activeDelivery.parcelShop;
    if (!alternativeAddress && !parcelShop) {
        return _resolve();
    }
    return addressModel.setAlternativeAddress(alternativeAddress, parcelShop);
}

function _resolve() {
    return new Promise(function(resolve, reject) {
        resolve();
    });
}


function _getAPIData() {
    var parameters = {
        next: 1,
        PaymethodID: data.activePayment.id,
        payCnt: data.payment.payCnt,
        ValidateNextStep: 1,
        ShippingMethodID: data.activeDelivery.id,
        shipCnt: data.delivery.shipCnt
    };
    if (data.activeDelivery.parcelShop) {
        var key = 'GlsParselShopID' + data.activeDelivery.id;
        parameters[key] = data.activeDelivery.parcelShop;
    }
    return parameters;
}

function _submitTermsAcceptedCall() {
    var parameters = _getTermsAcceptedData();
    _sumbitPOST('/shop/order3.html', parameters);
}

function _sumbitPOST(path, params) {
    var method = 'post';

    var form = document.createElement('form');
    form.setAttribute('method', method);
    form.setAttribute('action', path);

    for (var key in params) {
        if (params.hasOwnProperty(key)) {
            var hiddenField = document.createElement('input');
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            hiddenField.setAttribute('value', params[key]);

            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
}

function _registerEventListeners() {
    addressModel.addEventListener(function() {
        data.delivery = undefined;
        Cookies.remove('delivery');
    });
}

function _getTermsAcceptedData() {
    var parameters = {
        next: 1,
        pid: data.activePayment.id,
        shid: data.activeDelivery.id,
        acceptTermsBit: 'ON',
        ValidateNextStep: 1
    };
    return parameters;
}

/*function getIdentifier() {
    return data.identifier;
}*/

function reset() {
    Cookies.remove('delivery');
    Cookies.remove('payment');
    Cookies.remove('identifier');
    Cookies.remove('activeDelivery');
    Cookies.remove('activePayment');
}

// Module public functions
var publicFunctions = {
    getDeliveryMethods: getDeliveryMethods,
    setActiveDeliveryMethod: setActiveDeliveryMethod,
    setActiveDeliveryMethodID: setActiveDeliveryMethodID,
    getActiveDeliveryMethod: getActiveDeliveryMethod,
    setAlternativeAddress: setAlternativeAddress,
    getPaymentMethods: getPaymentMethods,
    setActivePaymentMethodID: setActivePaymentMethodID,
    setActivePaymentMethod: setActivePaymentMethod,
    getActivePaymentMethod: getActivePaymentMethod,
    addEventListener: addEventListener,
    submit: submit,
    reset: reset
    //getIdentifier: getIdentifier
};

// Exports the module
module.exports = (function() {
    _initialize();
    return publicFunctions;
})();
