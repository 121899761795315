'use strict';

var R = require(29);
var isIE = window.oldie || false;
var checkout = require(32);

var oldHref = '';

if (!isIE) {
    R(R.EVENTS.LOAD, [R.SECTIONS.DETAILS, R.SECTIONS.SHIPMENT_PAYMENT, R.SECTIONS.APPROVE, R.SECTIONS.CONFIRMATION], function() {
        var newHref = window.location.href;
        if (oldHref !== newHref) {
            oldHref = newHref;
            checkout.initialize(true);
        }
    });
} else {
    R(R.EVENTS.LOAD, [R.SECTIONS.DETAILS], function() {
        var newHref = window.location.href;
        if (oldHref !== newHref) {
            oldHref = newHref;
            $(function() {
                $('select[name="res1"] > option:eq(0)').replaceWith('<option value=""></option>');
                $('select[name="res1"] > option:eq(4)').replaceWith('<option value="Ingen" selected="">Ingen</option>');
                $('select[name="res1"]').css({ 'display': '', 'visibility': 'hidden' });
                $('#Field11_0 > .LabelColumn').css({ 'display': '', 'visibility': 'hidden' });
                $('#Content > table:eq(0) > tbody:eq(0)').css({ 'display': 'none', 'visibility': '' });
                $('.CustomerLookup_Information').css({ 'display': 'none', 'visibility': '' });
                $('hr').css({ 'display': 'none', 'visibility': '' });
                $('#Field6_0').css({ 'display': 'none', 'visibility': '' });
                $('#Field8_0').css({ 'display': 'none', 'visibility': '' });
            });
        }
    });
}
