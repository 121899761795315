'use strict';

var mq = window.mq;

var o = require(6);
var g = require(4);
var serviceConfig = require(7).trustpilot;

var optimizely = require(81);

var businessUnitId = serviceConfig.businessUnitId;
var publicAPIKey = serviceConfig.publicAPIKey;

// fetch trustpilot rating from API and build custom rating badge for smaller screens
function getRating() {
    if (sessionStorage['trustpilot']) {
        var data = sessionStorage.getItem('trustpilot');
        _buildRating(JSON.parse(data));
    } else {
        $.ajax({
            method: 'GET',
            url: 'https://api.trustpilot.com/v1/business-units/' + businessUnitId,
            timeout: 2000,
            headers: {
                'apikey': publicAPIKey
            },
            success: function(response) {
                if (window.sessionStorage) {
                    sessionStorage.setItem('trustpilot', JSON.stringify(response));
                }
                _buildRating(response);
            },
            error: function(err, status) {
                console.error('trustpilot error:', err);
            }
        });
    }
}

function _buildRating(data) {
    var score = data.trustScore;
    var reviews = data.numberOfReviews.total;
    var stars = data.stars;

    // 7.9 ud af 10 baseret på  5.227 anmeldelser
    var title = score + ' ud af 10 baseret på ' + reviews + ' anmeldelser';
    var tpClass = 'stars-' + stars;

    var tp = $('.js-trustPilot');
    tp.attr('title', title);
    tp.find('.js-trustPilotRating').addClass(tpClass);
}

function _loadScript() {
    var ref = document.getElementsByTagName('script')[0];
    var script = document.createElement('script');
    script.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.sync.bootstrap.min.js';
    script.onerror = function() { $('.js-trustPilotCheckoutWidget').remove(); };
    ref.parentNode.insertBefore(script, ref);
}

// load trust pilot widget on product page
function loadWidget() {

    var widget = '<div class="trustpilot-widget u-text--center" data-businessunit-id="469147610000640005001940" data-locale="da-DK" data-style-height="28px" data-style-width="100%" data-template-id="5406e65db0d04a09e042d5fc" data-theme="light"></div>';

    var trustPilotContainer = document.querySelector('.js-trustPilotWidget');
    trustPilotContainer.innerHTML = widget;

    _loadScript();

}

// load trust pilot widget on checkout steps
function loadCheckoutWidget() {

    var widget1 = '<div class="trustpilot-widget" data-locale="da-DK" data-template-id="539ad0ffdec7e10e686debd7" data-businessunit-id="469147610000640005001940" data-style-height="350px" data-style-width="345px" data-theme="light" data-stars="3,4,5"></div>';

    var widget2 = '<div class="trustpilot-widget" data-locale="da-DK" data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="469147610000640005001940" data-style-height="150px" data-style-width="100%" data-theme="light"></div>';

    var trustPilotContainer = document.querySelector('.js-trustPilotCheckoutWidget');

    if (trustPilotContainer) {
        optimizely.runTest(o.TRUSTPILOT_CHECKOUT, g[o.TRUSTPILOT_CHECKOUT], function(index) {

            if (trustPilotContainer.children.length) {
                return;
            }

            switch (index) {
                case 1:
                    trustPilotContainer.innerHTML = widget1;
                    _loadScript();
                    break;
                case 2:
                    trustPilotContainer.innerHTML = widget2;
                    _loadScript();
                    break;
                case 0:
                    $('.js-trustPilotCheckoutWidget').remove();
                    break;
            }

        }, function() {
            $('.js-trustPilotCheckoutWidget').remove();
        });

    }


}

module.exports = {
    getRating: getRating,
    loadWidget: loadWidget,
    loadCheckoutWidget: loadCheckoutWidget
};
