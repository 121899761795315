'use strict';

var Handlebars = require(116);
var currencyParser = require(64);

Handlebars.registerHelper('calculateFee', function(products, deliveryPrice, paymentPrice, paymentFee) {
    var totalPrice = 0,
        fee = 0;
    if (products) {
        for (var i = products.length - 1; i >= 0; i--) {
            totalPrice = totalPrice + (products[i].quantity * products[i].price);
        }
        if (typeof deliveryPrice !== 'undefined' && deliveryPrice) {
            totalPrice = totalPrice + deliveryPrice;
        }
        if (typeof paymentFee !== 'undefined' && paymentFee) {
            fee += (paymentFee / 100) * totalPrice;
        }
        if (typeof paymentPrice !== 'undefined' && paymentPrice) {
            fee += paymentPrice;
        }
        return currencyParser(fee);
    }
    return currencyParser(0);
});
