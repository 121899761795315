'use strict';

module.exports = {

    mail: 'cinemagic@cinemagic.dk',

    errorMessage: {
        DAO: 'DAO API failed, parcel shop was not selected.'
    }

};
