/* global ga */
'use strict';

var R = require('../../helpers/_rhythm');

var $ = jQuery = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);
var Cookies = require('js-cookie');
var History = require('html5-history');

require('bootstrap/dist/js/bootstrap');
require('vendor/floatlabels');

// Loading Handlebars, templates and helper
var Handlebars = require('hbsfy/runtime');
var checkoutTemplate = require('templates/checkout/checkout');
var customerTemplate = require('templates/checkout/customer');
require('modules/handlebars/helpers/select.step.js');
var detectBrowser = require('modules/util/detectBrowser');

//Loading controller
var controller = require('modules/controllers/checkout');

//Loading views
var summaryView = require('modules/views/summary');
var Loader = require('modules/views/loader');

// Loading utils
var Validator = require('modules/util/validator');

//Loading models
var basketModel = require('modules/models/basket');
var addressModel = require('modules/models/checkout/address');

var initialised = false;


function render() {
    var isB2BLoggedin = $('.Link_Topmenu[href="/shop/b2blogin.html?Logout=1"]').length > 0;
    if (isB2BLoggedin) {
        //$('.webshop-body').addClass('webshop-b2b-order')
        //return;
    }

    _unbindEventListeners();

    basketModel.getData().then(function(basket) {
        if (!basket.items.length) {
            controller.basketEmpty();
        }

        var checkSum = $('input[name="Order1CheckSum"]').val() || Cookies.getJSON('checkSum');
        Cookies.set('checkSum', checkSum, { expires: 0.5 });

        $('body').removeClass().addClass('webshop-orderstep1 webshop-body');
        $('body').attr('id', 'bodyCheckout').find('.basket-value-props').appendTo($('.top-bar > .top-bar-section > .row'));
        $('.MainContainer').html(checkoutTemplate({ step: 'kunde' }));
        $('#checkout-content').html(customerTemplate());

        summaryView.render(basket);
        _populateForm();

        //Bootstrap popover
        $('[data-toggle="popover"]').popover();
        //Set floating labels

        var ie = detectBrowser.getIEVersion();
        if (ie === 9) {
            $('.text-input-field-order').floatlabel({ slideInput: false, showOnDefault: true });
        } else if (ie > 9) {
            $('.text-input-field-order').floatlabel({ slideInput: false, showOnFocus: true });
        } else {
            $('.text-input-field-order').floatlabel({ slideInput: false });
        }
        _initialise();
        //Customer Type
        _registerEventListeners();
        //ZIP code
        _getCityOnline($('#customerInformation'));
        ga('send', {
            hitType: 'event',
            eventCategory: 'Checkout',
            eventAction: 'Render',
            eventLabel: 'Customer',
            nonInteraction: true
        });
    });
}

function _initialise() {
    if (!initialised) {
        initialised = true;
        _addUnloadEventListener();
    }
}

function _selectCustomerType(type, reset) {
    var holder = $('#customerInformation'),
        inp = holder.find('input[name="CustTypeID"]'),
        el = holder.find('#customerType .btn[data-type="' + type + '"]');
    inp.val(type);
    el.siblings().removeClass('active');
    el.addClass('active');
    holder.removeClass('type-company type-private type-public');

    switch (type) {
        case 0:
            holder.addClass('type-private');
            $('#customerInformation #companyID').attr('data-fv-notempty', 'false').prop('disabled', true).hide();
            $('#customerInformation #ean').attr('data-fv-notempty', 'false').attr('data-fv-ean', 'false').prop('disabled', true).hide();

            if (reset) {
                $('#customerInformation').data('formValidation').resetForm();
            }
            break;
        case 1:
            holder.addClass('type-company');
            $('#customerInformation #companyID').attr('data-fv-notempty', 'true').prop('disabled', false).show();
            $('#customerInformation #ean').attr('data-fv-notempty', 'false').attr('data-fv-ean', 'false').prop('disabled', true).hide();
            if (reset) {
                $('#customerInformation').data('formValidation').resetForm();
            }
            break;
        case 2:
            holder.addClass('type-public');
            $('#customerInformation #companyID').attr('data-fv-notempty', 'true').prop('disabled', false).show();
            $('#customerInformation #ean').attr('data-fv-notempty', 'true').attr('data-fv-ean', 'true').prop('disabled', false).show();
            if (reset) {
                $('#customerInformation').data('formValidation').resetForm();
            }
            break;
    }
}

function _registerEventListeners() {

    $('#submitCustomerInformation').on('mousedown', function(event) {
        ga('send', {
            hitType: 'event',
            eventCategory: 'Checkout',
            eventAction: 'Continue',
            eventLabel: 'Address'
        });
    });

    //Validation
    Validator.bindValidation($('#customerInformation'), _submitData, _getCityOnline, ['name', 'address', 'company', 'zipcode', 'city', 'ean', 'phone', 'email']);

    $(document).on('click', '#customerInformation #customerType .btn', function(event) {
        event.preventDefault();
        var el = $(event.currentTarget),
            type = el.data('type');

        _selectCustomerType(type, true);
    });

    $('#zip').keyup(function() {
        _getCityOnline($('#customerInformation'));
    });
}

function _unbindEventListeners() {
    $(document).off('click', '#customerInformation #customerType .btn');
    if (typeof $('#customerInformation').data('formValidation') !== 'undefined') {
        $('#customerInformation').data('formValidation').destroy();
    }
}

function _addUnloadEventListener() {
    R(R.EVENTS.UNLOAD, R.SECTIONS.DETAILS, function() {
        _submitData($('#customerInformation'), true);
    });
}


function _getCityOnline(el) {
    var country = el.find('select[name="country"]').val();
    var postnr = el.find('#zip').val();
    if (country === 'DK' && postnr.length) {
        $.ajax({
            type: 'GET',
            url: 'https://postnr.io/?zipcode=' + postnr,
            dataType: 'json',
            async: true,
            success: function(text) {
                var response = text.result;
                if (!response.length) {
                    return;
                }
                el.find('#city').val(response);
                el.formValidation('updateStatus', 'city', 'VALID')
                    .formValidation('validateField', 'city');
            },
            error: function(err) {
                el.find('#city').val('');
                el.formValidation('updateStatus', 'city', 'NOT_VALIDATED');
                console.error('error fetching postnr:', err);
            }
        });
    }
}

function _submitData(form, onlyLocally) {
    var customer = {
        type: form.find('#customerType button.active').data('type'),
        name: form.find('#name').val(),
        address: form.find('#address').val(),
        zip: form.find('#zip').val(),
        city: form.find('#city').val(),
        country: form.find('#country :selected').data('value'),
        phone: form.find('#phone').val(),
        email: form.find('#email').val()
    };
    if (customer.type === 1) {
        customer.company = form.find('input[name="company"]').val();
    } else if (customer.type === 2) {
        customer.company = form.find('input[name="company"]').val();
        customer.ean = form.find('#ean').val();
        customer.rekvnr = form.find('#rekvnr').val();
    }
    if (!onlyLocally) {
        Loader.render($('#checkout-wrapper > .container > .row'));
        addressModel.setCustomer(customer).then(function() {
            Loader.remove();
            History.pushState({}, '', '/shop/order2.html');
        }, function() {
            Loader.remove();
        });
    } else {
        addressModel.setCustomerLocally(customer);
    }
}

function _populateForm() {
    var customer = addressModel.getCustomer();
    if (customer) {
        _selectCustomerType(customer.type);
        var form = $('#customerInformation');
        form.find('#name').val(customer.name);
        form.find('#address').val(customer.address);
        form.find('#zip').val(customer.zip);
        form.find('#city').val(customer.city);
        form.find('#country option').removeAttr('selected');
        form.find('#country option[data-value="' + customer.country + '"]').attr('selected', 'selected');
        form.find('#phone').val(customer.phone);
        form.find('#email').val(customer.email);

        //ZIP code
        if ($('#zip').val() && !$('#city').val()) {
            //_getCityOnline($('#customerInformation'));
        }

        if (typeof customer.company !== 'undefined' && customer.company.length) {
            form.find('input[name="company"]').val(customer.company);
        }
        if (typeof customer.ean !== 'undefined' && customer.ean.length) {
            form.find('#ean').val(customer.ean);
        }
        if (typeof customer.rekvnr !== 'undefined' && customer.rekvnr.length) {
            form.find('#rekvnr').val(customer.rekvnr);
        }
    }
}


// Exports the module
module.exports = {
    render: render
};
